.header {
  position: relative;
  color: white;
  text-align: center;
  padding-bottom: 10px;
}

.header::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 10%;
  width: 80%;
  border-bottom: 1px solid #ffffff;
}
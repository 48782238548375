.project-container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  color: white;
}

.project-box {
  flex: 1 1 33%;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  height: 400px;
  width: 400px;
}

.project-box:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.project-thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.project-title {
  margin-top: 10px;
  font-size: 1.2em;
}